<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-overlay :show="loadingState">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="museum.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :disabled="museum.org_id != ''"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Museum Name (En)" vid="museum_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.museum_name_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="name"
                                    v-model="museum.museum_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Museum Name (Bn)" vid="museum_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="designation"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.museum_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="designation"
                                    v-model="museum.museum_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Museum Description (En)" vid="description" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.museum_description_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                    id="museum_description"
                                    v-model="museum.description"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Museum Description (Bn)" vid="description_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.museum_description_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                    id="museum_description_bn"
                                    v-model="museum.description_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Museum Location" vid="location">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="designation"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.museum_location')}}
                                    </template>
                                    <b-form-input
                                    id="museum_location"
                                    v-model="museum.location"
                                    ></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Other Information (En)" vid="other_information">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="other_information"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.other_information_en')}}
                                    </template>
                                    <b-form-input
                                    id="other_information_en"
                                    v-model="museum.other_information"
                                    ></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Other Information (Bn)" vid="other_information_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="other_information_bn"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.other_information_bn')}}
                                    </template>
                                    <b-form-input
                                    id="other_information_bn"
                                    v-model="museum.other_information_bn"
                                    ></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Contact Us" vid="contact_us">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="contact_us"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.contact_us')}}
                                    </template>
                                    <b-form-input
                                    id="contact_us"
                                    v-model="museum.contact_us"
                                    ></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-3')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </div>
                        </b-form>
                    </b-overlay>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { museumInfoStoreApi, museumInfoUpdateApi } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      this.museum.org_id = this.$store.state.dataFilters.orgId
    if (this.id) {
      const tmp = this.getmuseum()
      this.museum = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        loadingState: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        museum: {
            org_id: 0,
            museum_name: '',
            museum_name_bn: '',
            description: '',
            description_bn: '',
            location: '',
            schedule: '',
            other_information: '',
            other_information_bn: '',
            contact_us: ''
        }
    }
  },
  computed: {
      orgList: function () {
          const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      }
  },
  methods: {
    getmuseum () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.loadingState = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.id) {
       result = await RestApi.putData(
          virtualMuseumServiceBaseUrl,
          `${museumInfoUpdateApi}/${this.id}`,
          this.museum
        )
      } else {
        result = await RestApi.postData(virtualMuseumServiceBaseUrl, museumInfoStoreApi, this.museum)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
            this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-3')
      } else {
            this.$refs.form.setErrors(result.errors)
      }
      this.loadingState = false
    }
  }
}
</script>
